export const isProd = process.env.NODE_ENV === "production";

/**
 * 企业id
 */
export const corpid = isProd ? "ww1792ac25c99153bd" : "ww7ed954366e02f890";

/**
 * 应用 secret
 */
export const secret = isProd
  ? "7DJrB4npFRP8WDvDEUy5vrPS0xU_Rq-BRORDPDrr-M0"
  : "TDGHeVUklq9kUESmN-dFaPj_2bYBzCLnxc_m7vhf9pU";

/**
 * 应用 agentId
 */
export const agentId = isProd ? 1000012 : 1000003;

export const domainUrl = (): string => {
  switch (process.env.NODE_ENV) {
    case "production":
      return "https://manhattan-sidebar.agoralab.co";
    case "development":
      return "https://manhattan-sidebar.sh2.agoralab.co";
    case "staging":
      return "http://localhost:8080";
    default:
      return "https://manhattan-sidebar.agoralab.co";
  }
};

export const domain = domainUrl();

const config = {
  isProd,
  isTest: process.env.NODE_ENV === "test",
  isDev: process.env.NODE_ENV === "development",
  agentId,
  domain,
  secret,
  corpid,
};
export default config;
