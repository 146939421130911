import { key, store } from "@/store";
import Vant from "vant";
// import "vant/lib/index.less"; // 这里不引入vant css的原因是会引起打包错误，处理方式在index.html中直接引入，采用全局引入vant的原因也是一样的！ van3.0打包 死坑
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./style/app.less";
import "./style/global.less";

createApp(App).use(store, key).use(router).use(Vant).mount("#app");
