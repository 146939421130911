import { CustomerCompanyInfo, CustomerVendorInfo, fetchChatGroupInfo, fetchCustomerArcherInfo, updateChatGroupInfo } from '@/views/customer/service';
import { UserInfo } from '@/views/service';
import Notify from 'vant/lib/notify';
import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import jiraModule from './modules/Jira';
import RootStateTypes, { AllStateTypes, Option } from './types';

export const store = createStore<RootStateTypes>({
  state: {
    chatID: '', // 群id目前就一个用处，到后端换cid
    CID: '',
    VID: '',
    companyInfo: null,
    projectInfo: [],
    loading: false,
    userInfo: undefined,
    vidOptions: [],
  },
  getters: {},
  mutations: {
    setChatID(state: RootStateTypes, payload: string) {
      state.chatID = payload || '';
    },
    setCID(state: RootStateTypes, payload: string) {
      state.CID = payload || '';
    },
    setLoading(state: RootStateTypes, payload: boolean) {
      state.loading = payload || false;
    },
    setVID(state: RootStateTypes, payload: string) {
      state.VID = payload || '';
    },
    setVidOptions(state: RootStateTypes, payload: Option[]) {
      state.vidOptions = payload;
    },
    setUserInfo(state: RootStateTypes, payload: UserInfo) {
      state.userInfo = payload;
    },
    setCompanyInfo(state: RootStateTypes, payload: CustomerCompanyInfo) {
      state.companyInfo = payload;
    },
    setProjectInfo(state: RootStateTypes, payload: CustomerVendorInfo[]) {
      state.projectInfo = payload || [];
    },
  },
  actions: {
    async fetchCustomerArcherInfo({ commit }, payload) {
      fetchCustomerArcherInfo(payload)
        .then((result) => {
          if (result) {
            commit('setCompanyInfo', result.company);
            commit('setProjectInfo', result.vendor);
            if (result.vendor && result.vendor.length) {
              result.vendor.sort((a, b) => (b['vid.agora_vid_usage_cube_wow_v.current_week_usage'] || 0) - a['vid.agora_vid_usage_cube_wow_v.current_week_usage'] || 0);
              const vidOptions = result.vendor.map((v) => {
                return {
                  text: `${v.vid} - ${v.vid_nickname}`,
                  value: v.vid,
                };
              });
              commit('setVidOptions', vidOptions);
              commit('setVID', vidOptions[0].value || '');
            }
          } else {
            Notify({
              type: 'danger',
              message: '获取客户数据失败,请检查CID是否正确或者稍后重新重试',
            });
          }
        })
        .catch(() => {
          Notify({
            type: 'danger',
            message: '获取客户数据失败,请检查CID是否正确或者稍后重新重试',
          });
        });
    },
    async fetchChatGroupInfo({ commit }, payload) {
      if (payload) {
        const result = await fetchChatGroupInfo(payload);
        await commit('setCID', result);
      }
    },
    async updateChatGroupInfo(_, payload) {
      try {
        await updateChatGroupInfo(payload);
      } catch (err) {
        Notify({ type: 'danger', message: '存储CID失败' });
      }
    },
  },
  modules: {
    jira: jiraModule,
  },
});

export const key: InjectionKey<Store<RootStateTypes>> = Symbol('vue-store');

export function useStore<T = AllStateTypes>(): Store<T> {
  return baseUseStore<T>(key);
}
