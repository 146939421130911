
import { useStore } from "@/store";
import { openUrl } from "@/utils";
import { translationArcherVendorModel } from "../model";
import { computed, defineComponent, onMounted, reactive } from "vue";
import Info from "./../Info.vue";
import { Dialog } from "vant";

export default defineComponent({
  name: "customer",
  components: {
    Info,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      loading: false,
      refreshing: false,
      companyInfo: computed(() => {
        const data = store.state.companyInfo;
        if (data) {
          return translationArcherVendorModel(data, [
            "customer_type", // 客户类型
            "customer",
            "company_id.wide_company.company_name",
            "company_id.wide_company.current_sp_support_level_str", //服务等级
            "industry",
            "company_id.wide_company.sales_email", // 商务
            "company_id.wide_company.sp_company_owner_1_email", // owner
            "company_tam",
            "company_sa",
            "company_cse",
            // "company_id.wide_company.sp_company_backup_1_email", //backup1
            // "company_id.wide_company.sp_company_backup_2_email", //backup2
            "stakeholders", //主要干系人以及组织架构 => 微信群主要联系人
            "company_id.csdc_company_product_types.uap_configured_product_types", //开通云服务
            "company_id.csdc_company_product_types.used_inweek_product_types", //有用量云服务
            "company_id.csdc_company_product_types.ad_products_used_inweek_app_types", //有用量的第三方框架
            "company_id.csdc_company_product_types.rtm_used_or_not", // RTM是否有周用量
            "company_id.csdc_company_sdk_usage.android_max_usage_ver",
            "company_id.csdc_company_sdk_usage.android_max_ver",
            "company_id.csdc_company_sdk_usage.ios_max_usage_ver",
            "company_id.csdc_company_sdk_usage.ios_max_ver",
            "company_id.csdc_company_sdk_usage.windows_max_usage_ver",
            "company_id.csdc_company_sdk_usage.windows_max_ver",
            "company_id.csdc_company_sdk_usage.mac_max_usage_ver",
            "company_id.csdc_company_sdk_usage.mac_max_ver",
            "company_id.csdc_company_sdk_usage.web_max_usage_ver",
            "company_id.csdc_company_sdk_usage.web_max_ver",
            "oncall_info", //值班须知
            "customer_remark", //客户备注
          ]);
        }
        return [];
      }),
    });

    const resetCid = () => {
      Dialog.confirm({
        message: "确认要切换CID?",
      }).then(() => {
        store.commit("setCompanyInfo", null);
        store.commit("setProjectInfo", []);
        store.commit("jira/setJiraList", []);
        store.commit("setCID", "");
      });
    };

    const onRefresh = async () => {
      state.refreshing = true;
      await store.dispatch("fetchCustomerArcherInfo", store.state.CID);
      state.refreshing = false;
    };

    // onMounted(async () => {
    //   state.loading = true;
    //   await store.dispatch("fetchCustomerArcherInfo", store.state.CID);
    //   state.loading = false;
    //   await store.commit("jira/setLoading", true);
    //   await store.dispatch("jira/fetchJiraList", store.state.CID);
    //   await store.commit("jira/setLoading", false);
    // });

    const jumpToArcher = () => {
      openUrl(
        "https://datawich.agoralab.co/v2/data-app/csdc_company?csdc_company.company_id=" +
          store.state.CID +
          "&pageNumber=1&pageSize=10&sortDirection=descending&company_id.csdc_company_product_types.uap_configured_product_types=0&company_id.csdc_company_product_types.used_inweek_product_types=0&company_id.csdc_company_product_types.ad_products_used_inweek_app_types=0&company_id.csdc_company_product_types.ncs_configured_types=0&company_id.csdc_company_product_types.rtm_weekly_usage=&company_id.csdc_company_product_types.rtm_used_or_not=0&company_id.wide_company.company_name=&company_id.wide_company.sales_email=&company_id.wide_company.company_last_month_mr_level=&company_id.wide_company.current_sp_apply_background=&company_id.wide_company.sp_apply_competitors_ids=&company_id.wide_company.sp_company_owner_1_email=&company_id.wide_company.sp_company_backup_1_email=&company_id.wide_company.sp_company_backup_2_email=&company_id.wide_company.current_sp_support_level_str=&company_id.wide_company.sp_apply_company_industry_id_str=&csdc_company.area=&csdc_company.customer_okr=&csdc_company.stakeholders=&csdc_company.report=&csdc_company.documents=&csdc_company.wechat_group=&csdc_company.activity=&csdc_company.customer_type=0&csdc_company.epic=&csdc_company.tags=0&csdc_company.visit=&csdc_company.important=&csdc_company.customer_remark=&csdc_company.competitors=0"
      );
    };

    return {
      resetCid,
      state,
      jumpToArcher,
      onRefresh,
      store,
    };
  },
});
