import request from "@/utils/request";
export type CustomerInfoValue = null | string | number;
export interface CustomerVendorInfo {
  rid: number;
  data_id: string;
  vid: string;
  vid_nickname: string;
  app: string;
  vendor_type: string;
  project_stage: string;
  vendor_id: number;
  author: string;
  update_author: string;
  create_time: string;
  update_time: string;
  vendor_industry: string;
  company_id: string;
  competitors: number;
  competitor_info: string;
  os: number;
  stakeholders: string;
  function_unit: number;
  uid: string;
  tam: string;
  sa: string;
  cse: string;
  strategy: string;
  project_comments: string;
  "company_id.csdc_company.area": string;
  "company_id.wide_company.sales_email": string;
  "company_id.wide_company.company_last_month_mr_level": string;
  "company_id.wide_company.sp_company_owner_1_email": string;
  "company_id.wide_company.sp_company_backup_1_email": string;
  "company_id.wide_company.current_sp_support_level_str": string;
  "vid.agora_vid_usage_cube_wow_v.current_week_usage": number;
  "vid.agora_vid_usage_cube_wow_v.current_week_over_week": number;
  "vid.csdc_vender_sdk_usage.use_case": CustomerInfoValue;
  "vid.csdc_vendor_jira_list.csd_bug_unresolved_list": CustomerInfoValue;
  "vid.csdc_vendor_jira_list.csd_bug_unresolved_amount": CustomerInfoValue;
  "vid.csdc_vendor_jira_list.csd_newfeature_unresolved_list": CustomerInfoValue;
  "vid.csdc_vendor_jira_list.csd_newfeature_unresolved_amount": CustomerInfoValue;
  "vid.csdc_vendor_product_types.uap_configured_product_types": number;
  "vid.csdc_vendor_product_types.used_inweek_product_types": number;
  "vid.csdc_vendor_product_types.rtm_weekly_usage": number;
  "vid.csdc_vendor_product_types.rtm_used_or_not": number;
  "vid.csdc_vendor_product_types.ncs_configured_types": number;
  "vid.csdc_vendor_product_types.ad_products_used_inweek_app_types": number;
  _company_id: number;
  _company_name: string;
  _project_id: string;
  _project_name: string;
}

export interface CustomerCompanyInfo {
  rid: number;
  data_id: string;
  customer: string;
  author: string;
  update_author: string;
  create_time: string;
  update_time: string;
  company_id: string;
  first_sp_level: string;
  area: string;
  customer_okr: string;
  stakeholders: string;
  report: string;
  documents: string;
  wechat_group: string;
  activity: string;
  customer_type: number;
  epic: string;
  tags: number;
  visit: string;
  important: string;
  customer_remark: string;
  competitors: number;
  competitor_info: CustomerInfoValue;
  oncall_info: CustomerInfoValue;
  "company_id.agora_cid_usage_cube_week_v.current_week_usage": number;
  "company_id.agora_cid_usage_cube_week_v.current_over": number;
  "company_id.agora_cid_usage_cube_week_v.last_week_over_week": number;
  "company_id.csdc_company_jira_list.csd_bug_unresolved_list": CustomerInfoValue;
  "company_id.csdc_company_jira_list.csd_bug_unresolved_amount": CustomerInfoValue;
  "company_id.csdc_company_jira_list.csd_newfeature_unresolved_list": CustomerInfoValue;
  "company_id.csdc_company_jira_list.csd_newfeature_unresolved_amount": CustomerInfoValue;
  "company_id.csdc_company_product_types.uap_configured_product_types": number;
  "company_id.csdc_company_product_types.used_inweek_product_types": number;
  "company_id.csdc_company_product_types.ad_products_used_inweek_app_types": number;
  "company_id.csdc_company_product_types.ncs_configured_types": number;
  "company_id.csdc_company_product_types.rtm_weekly_usage": number;
  "company_id.csdc_company_product_types.rtm_used_or_not": number;
  "company_id.csdc_company_sdk_usage.cid": number;
  "company_id.csdc_company_sdk_usage.android_max_usage_ver": string;
  "company_id.csdc_company_sdk_usage.android_max_ver": string;
  "company_id.csdc_company_sdk_usage.ios_max_usage_ver": CustomerInfoValue;
  "company_id.csdc_company_sdk_usage.ios_max_ver": CustomerInfoValue;
  "company_id.csdc_company_sdk_usage.windows_max_usage_ver": CustomerInfoValue;
  "company_id.csdc_company_sdk_usage.windows_max_ver": CustomerInfoValue;
  "company_id.csdc_company_sdk_usage.mac_max_usage_ver": CustomerInfoValue;
  "company_id.csdc_company_sdk_usage.mac_max_ver": CustomerInfoValue;
  "company_id.csdc_company_sdk_usage.web_max_usage_ver": CustomerInfoValue;
  "company_id.csdc_company_sdk_usage.web_max_ver": CustomerInfoValue;
  "company_id.wide_company.company_name": string;
  "company_id.wide_company.sales_email": string;
  "company_id.wide_company.company_last_month_mr_level": string;
  "company_id.wide_company.current_sp_apply_background": string;
  "company_id.wide_company.sp_apply_competitors_ids": string;
  "company_id.wide_company.sp_company_owner_1_email": string;
  "company_id.wide_company.sp_company_backup_1_email": string;
  "company_id.wide_company.sp_company_backup_2_email": string;
  "company_id.wide_company.current_sp_support_level_str": string;
  "company_id.wide_company.sp_apply_company_industry_id_str": string;
}

export interface ArcherDataModel {
  modelKey: string;
  fieldKey: string;
  name: string;
  fieldType: string;
  createTime: string;
  updateTime: string;
  options: Option[];
  value2LabelMap: {
    [key: string]: string;
  };
}

export interface Option {
  label: string;
  value: string | number;
  restraintValueMap: {
    [key: string]: string;
  };
}

export interface ICustomerArcherInfo {
  company: CustomerCompanyInfo;
  companyStructure: ArcherDataModel[];
  vendor: CustomerVendorInfo[];
  vendorStructure: ArcherDataModel[];
}

export const fetchCustomerArcherInfo = (
  cid: string
): Promise<ICustomerArcherInfo> => {
  return request.get("/customer/archer", { params: { cid } });
};

export const fetchChatGroupInfo = (chatId: string): Promise<string> => {
  return request.get("/chat-group/info", { params: { chatId } });
};

export const updateChatGroupInfo = (data: {
  cid: string;
  chatId: string;
}): Promise<void> => {
  return request.post("/chat-group/info", data);
};
