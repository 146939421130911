import axios from 'axios';
import { Notify } from 'vant';

// 白名单内错误不通知
const notifyWhiteUrl: string[] = [];

const codeMessage: { [key: number]: string } = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限或者token过期。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  405: '请求方法不被允许。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
  1001: '获取客户jira数据失败',
  1002: '获取工时数据失败',
  2001: '微信code码错误',
  1102: '企业微信接口错误,换取用户凭证接口失败。可能短时间打开侧边栏太频繁',
  1103: '企业微信接口错误,换取用户凭证ID为空。可能短时间打开侧边栏太频繁',
  1104: '获取取用户信息失败',
  3001: '当前cid找不到相关公司信息',
  3002: 'cid 不能为空',
};

const baseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return '/api/v2/we-com';
    case 'staging':
      return '/api/v2/we-com';
    case 'development':
      return 'http://localhost:8900/api/v2/we-com';
    default:
      return 'http://localhost:8900/api/v2/we-com';
  }
};

export const WeComLoginUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww1792ac25c99153bd&redirect_uri=https://manhattan-sidebar.agoralab.co&response_type=code&scope=snsapi_userinfo&agentid=1000012&state=STATE#wechat_redirect';
    case 'staging':
      return 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww7ed954366e02f890&redirect_uri=https://manhattan-sidebar.sh2.agoralab.co&response_type=code&scope=snsapi_userinfo&agentid=1000003&state=STATE#wechat_redirect';
    case 'development':
      return 'http://localhost:8901';
    default:
      return 'http://localhost:8901';
  }
};

const request = axios.create({
  baseURL: baseUrl(),
  timeout: 1000 * 90,
  withCredentials: true,
});

request.interceptors.response.use(
  function (response) {
    if (response.data.code === 200) {
      return response.data.data;
    }
    return response.data;
  },
  function (err) {
    const { config, request, response } = err;
    if (response && response.status) {
      const { status, url, statusText, data } = response;
      const errorText = codeMessage[data.msg as number] || data.msg || codeMessage[status] || statusText;
      if (response.status === 401) {
        window.location.replace(WeComLoginUrl());
        return;
      }
      if (!notifyWhiteUrl.includes(url)) {
        Notify({ type: 'danger', message: errorText });
      }
    }

    if (!response) {
      Notify({ type: 'danger', message: '网络请求发生异常，无法连接服务器' });
    }
    return err;
  }
);

export default request;
