import { fetchJira, Issue } from "@/views/jira/service";
import Notify from "vant/lib/notify";
import { Module } from "vuex";
import RootStateTypes from "../../types";
import jiraFactoryStateTypes from "./types";

// Create a new store Modules.
const jiraModule: Module<jiraFactoryStateTypes, RootStateTypes> = {
  namespaced: true,
  state: {
    list: [],
    loading: false,
  },
  mutations: {
    setJiraList(state: jiraFactoryStateTypes, payload: Issue[]) {
      state.list = payload || [];
    },
    setLoading(state: jiraFactoryStateTypes, payload: boolean) {
      state.loading = payload;
    },
  },
  actions: {
    async fetchJiraList({ commit }, payload) {
      try {
        const result = await fetchJira({ cid: payload });
        await commit("setJiraList", result.issues);
        return result;
      } catch {
        Notify({ type: "danger", message: "获取jira数据失败请稍后重试" });
      }
    },
  },
};

export default jiraModule;
