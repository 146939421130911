import request from '@/utils/request';

export interface UserInfo {
  userid: string;
  name: string;
  department: number[];
  order: number[];
  position: string;
  mobile: string;
  gender: string;
  email: string;
  alias: string;
  status: number;
  address: string;
  english_name: string;
  open_userid: string;
  qr_code: string;
  external_position: string;
  avatar: string;
  main_department: number;
}

export const fetchUserInfo = (code: string): Promise<UserInfo> =>
  request.get('/user/info', {
    params: { code },
  });

export const fetchAccount = (): Promise<UserInfo> => request.get('/account');
