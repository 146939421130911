
import { useStore } from "@/store";
import { openJira, openUrl } from "@/utils";
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "vue";
import { Issue } from "./service";
const stageOpts = [
  {
    text: "未解决",
    value: "未解决",
  },
  {
    text: "已解决",
    value: "已解决",
  },
];

const stageDic: { [key: string]: string[] } = {
  未解决: ["new", "In Progress", "Escalated", "Pending on Support", "Reopened"],
  已解决: [
    "Resolved",
    "Declined",
    "Closed",
    "QA Verified",
    "Waiting for customer",
  ],
};

const priorityOpts = [
  { text: "优先级", value: "优先级" },
  {
    text: "Blocker",
    value: "Blocker",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/blocker.svg",
  },
  {
    text: "Highest",
    value: "Highest",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/major.svg",
  },
  {
    text: "High",
    value: "High",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/critical.svg",
  },
  {
    text: "Medium",
    value: "Medium",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/medium.svg",
  },
  {
    text: "Low",
    value: "Low",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/low.svg",
  },
  {
    text: "Minor",
    value: "Minor",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/minor.svg",
  },
];

const priorityIconMap = priorityOpts.reduce((pre, next) => {
  pre[next.value] = next.icon;
  return pre;
}, {} as any);

const vidOpts = [
  {
    text: "vid",
    value: "vid",
  },
];
type DataWatchT = [string, string, string, Issue[]];
export default defineComponent({
  name: "jira",
  setup() {
    const store = useStore();

    const state = reactive<{
      loading: ComputedRef<boolean>;
      stage: string;
      priority: string;
      title: string;
      data: Issue[];
      refreshing: boolean;
      showPopup: boolean;
      vid: string;
      vidOpts: ComputedRef<{ text: string; value: string }[]>;
      jql: string;
    }>({
      loading: computed(() => store.state.jira.loading),
      refreshing: false,
      stage: stageOpts[0].value,
      priority: priorityOpts[0].value,
      vid: vidOpts[0].value,
      title: "",
      jql: "",
      data: [],
      showPopup: false,
      vidOpts: computed(() => {
        const { vidOptions } = store.state;
        return [...vidOpts, ...vidOptions];
      }),
    });

    const onVidChange = (v: string) => {
      store.commit("setVID", v);
    };

    const openTitle = (title: string): void => {
      state.title = title;
      state.showPopup = true;
    };

    const openFilter = () => {
      openUrl("https://jira.agoralab.co/issues/?jql=" + state.jql);
    };

    const computedData = ([vid, stage, priority, list]: DataWatchT) => {
      const stages = stageDic[stage as string];
      state.data = (list as Issue[]).filter((v) => {
        let flag = stages.includes(v.fields.status.name);
        if (priority !== priorityOpts[0].value) {
          flag = flag && v.fields.priority.name === priority;
        }
        if (vid !== vidOpts[0].value) {
          flag = flag && v.fields.customfield_12600.trim() === vid;
        }
        return flag;
      });

      let jql = `project = CSD AND status in (${stages
        .map((v) => `'${v === "In Progress" ? "InProgress" : v}'`)
        .join(",")})`;

      if (vid !== vidOpts[0].value) {
        jql = jql + ` AND VID ~ ${vid}`;
      } else {
        jql = jql + ` AND CompanyID ~ ${store.state.CID}`;
      }

      if (priority !== priorityOpts[0].value) {
        jql = jql + ` AND priority = ${priority}`;
      }
      state.jql = jql + ` ORDER BY created DESC`;
    };

    watch(
      (): DataWatchT => [
        state.vid,
        state.stage,
        state.priority,
        store.state.jira.list,
      ],
      computedData
    );

    const closePopup = () => {
      state.title = "";
    };

    const onRefresh = async () => {
      state.refreshing = true;
      await store.dispatch("jira/fetchJiraList", store.state.CID);
      state.refreshing = false;
    };

    onMounted(async () => {
      computedData([
        state.vid,
        state.stage,
        state.priority,
        store.state.jira.list,
      ]);
    });

    return {
      state,
      openTitle,
      onRefresh,
      onVidChange,
      openJira,
      openUrl,
      openFilter,
      closePopup,
      priorityOpts,
      stageOpts,
      priorityIconMap,
    };
  },
});
