
import InputCid from "@/components/InputCid/index.vue";
import CustomerCompany from "@/views/customer/company/index.vue";
import CustomerVendor from "@/views/customer/vendors/index.vue";
import Jira from "@/views/jira/index.vue";
import Work from "@/views/work/index.vue";
import { useStore } from "@/store";
import { agentConfig, getContext, getCurExternalChat } from "@/utils/wx";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { CustomerVendorInfo } from "./views/customer/service";
import { Store } from "vuex";
import { getQueryVariable } from "./utils";
import { fetchAccount, fetchUserInfo, UserInfo } from "./views/service";
import { AllStateTypes } from "./store/types";
import { Notify } from "vant";

const weComInit = async function (store: Store<AllStateTypes>) {
  let userInfo: UserInfo;
  const code = getQueryVariable("code");
  if (code && code !== "") {
    userInfo = await fetchUserInfo(code);
  } else {
    userInfo = await fetchAccount();
  }
  // 登录成功
  if (userInfo && userInfo.userid) {
    store.commit("setUserInfo", userInfo);
    const url = window.location.href;
    agentConfig(url, ["getContext", "getCurExternalChat"])
      .then(() => {
        getContext()
          .then((context) => {
            const { entry } = context;
            if (entry === "group_chat_tools") {
              getCurExternalChat()
                .then((curExternalChat) => {
                  const { chatId } = curExternalChat; //返回当前客户群的群聊ID
                  store.commit("setChatID", chatId);
                  store.dispatch("fetchChatGroupInfo", chatId);
                })
                .catch((e) => {
                  Notify({
                    type: "danger",
                    message: `企业微信getCurExternalChat接口调用错误。\n ${JSON.stringify(
                      e
                    )}`,
                  });
                });
            }
          })
          .catch((e) => {
            Notify({
              type: "danger",
              message: `企业微信getContext接口调用错误。\n ${JSON.stringify(
                e
              )}`,
            });
          });
      })
      .catch((e) => {
        Notify({
          type: "danger",
          message: `企业微信agentConfig接口调用错误。\n ${JSON.stringify(e)}`,
        });
      });
  } else {
    if (code && code !== "") {
      Notify({
        type: "danger",
        message: `企业微信认证失败，请稍后重新尝试打开。`,
      });
    }
  }
};
export default defineComponent({
  name: "App",
  components: {
    InputCid,
    CustomerCompany,
    CustomerVendor,
    Jira,
    Work,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      loading: computed(() => store.state.loading),
      userInfo: computed(() => store.state.userInfo),
      showContent: computed(() => {
        return !!(
          store.state.CID &&
          store.state.CID !== "" &&
          store.state.userInfo?.userid &&
          store.state.companyInfo &&
          store.state.companyInfo.company_id
        );
      }),
    });
    onMounted(async () => {
      // wxAuth();
      store.subscribe(async (action, state) => {
        if (action.type === "setProjectInfo") {
          const projectInfo: CustomerVendorInfo[] = action.payload;
          // 重置 当前vid
          if (
            projectInfo &&
            projectInfo.length > 0 &&
            projectInfo.every((v) => v.vid !== state.VID)
          ) {
            store.commit("setVID", projectInfo[0].vid);
          }
        }
        if (action.type === "setCID" && action.payload !== "") {
          store.commit("setLoading", true);
          await store.dispatch("fetchCustomerArcherInfo", action.payload);
          store.commit("setLoading", false);
          await store.commit("jira/setLoading", true);
          await store.dispatch("jira/fetchJiraList", action.payload);
          await store.commit("jira/setLoading", false);
        }
      });
      store.commit("setLoading", true);
      await weComInit(store);
      store.commit("setLoading", false);
    });
    return {
      state,
    };
  },
});
