import { AxiosRequestConfig } from 'axios';
import { agentId, corpid } from './config';
import request from './request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const wx: any = (window as any).wx;

// const REDIRECT_URI = "http://manhattan.test.utools.club";
// const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${agentId}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_userinfo&agentid=${agentId}&state=STATE#wechat_redirect`;

export const agentConfig = async (signatureUrl: string, jsApiList: string[]): Promise<any> => {
  const result = await fetchWeComSignature(signatureUrl, true);
  if (result && result.signature) {
    return new Promise((resolve, reject) => {
      try {
        wx.agentConfig({
          debug: true,
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentId, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: Number(result.timestamp), // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList, //必填，传入需要使用的接口名称
          success: function (data: any) {
            resolve(data);
          },
          fail: function (error: any) {
            if (error.errMsg.indexOf('function not exist') > -1) {
              alert('企业微信版本过低请升级');
            }
            reject(error);
          },
        });
      } catch (e) {
        reject(new Error(`获取企业微信api agentConfig 失败。err:${JSON.stringify(e)}`));
      }
    });
  }
  return new Error('企业微信获取签名请求错误。');
};

export const wxconfig = (signatureUrl: string, jsApiList: string[]): Promise<any> => {
  return fetchWeComSignature(signatureUrl, false)
    .then((result) => {
      wx.config({
        // beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: corpid, // 必填，企业微信的corpID
        timestamp: result.timestamp, // 必填，生成签名的时间戳
        nonceStr: result.nonceStr, // 必填，生成签名的随机串
        signature: result.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });
    })
    .catch((e) => {
      return new Error(`企业微信获取签名请求错误。err:${JSON.stringify(e)}`);
    });
};

export const checkJsApi = (jsApiList: string[]) =>
  new Promise<any>((resolve, reject) => {
    wx.checkJsApi({
      jsApiList: jsApiList,
      success: (res: any) => {
        resolve(res);
      },
      fail: (err: any) => {
        reject(err);
      },
    });
  });

// 获取入口值
export const getContext = (): Promise<any> =>
  new Promise((resolve, reject) => {
    wx.invoke('getContext', {}, function (result: any) {
      if (result.err_msg == 'getContext:ok') {
        resolve(result);
        //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
      } else {
        //错误处理
        reject(result);
      }
    });
  });

// 获取群id
export const getCurExternalChat = () =>
  new Promise<any>((resolve, reject) => {
    wx.invoke('getCurExternalChat', {}, function (result: any) {
      if (result.err_msg == 'getCurExternalChat:ok') {
        resolve(result);
      } else {
        //错误处理
        reject(result);
      }
    });
  });

export interface SignatureResp {
  timestamp: string;
  signature: string;
  nonceStr: string;
  ticket: string;
}

export const fetchWeComSignature = (url: string, isApp = false, options: AxiosRequestConfig = {}): Promise<SignatureResp> =>
  request.post<API.AxiosResponseT, SignatureResp>(
    '/signature',
    {
      url,
      isApp,
    },
    options
  );

export interface WeComJsApiTicket {
  ticket: string;
}
export const fetchWeComJsApiTicket = (isAgent = false): Promise<string> =>
  request
    .get<API.AxiosResponseT, WeComJsApiTicket>('/jsapi-ticket', {
      params: { isAgent: isAgent },
    })
    .then((result) => result.ticket);

export const fetchWecomAccessToken = async (): Promise<string> => request.get<API.AxiosResponseT, string>('/access-token');
