export const openUrl = (url: string): void => {
  window.open(url, '_blank');
};

export const openJira = (key: string): void => {
  window.open('https://jira.agoralab.co/browse/' + key, '_blank');
};

export function getQueryVariable(variable: string): string {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return '';
}
