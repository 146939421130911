import request from "@/utils/request";

interface FetchJiraReq {
  cid: string;
}

export interface Issue {
  changelog: Changelog;
  fields: Fields;
  id: string;
  key: string;
  self: string;
}

export interface Changelog {
  histories: History[];
  maxResults: number;
  startAt: number;
  total: number;
}

export interface History {
  created: string;
  items: Item[];
}

export interface Item {
  field: string;
  fieldtype: string;
  fromString: string;
  to: string;
  toString: string;
}

export interface Fields {
  aggregateprogress: Aggregateprogress;
  aggregatetimeestimate: number;
  aggregatetimeoriginalestimate: number;
  aggregatetimespent: number;
  assignee: Assignee;
  changeLog: any;
  comment: Comment;
  components: Component[];
  created: string;
  customfield_10100: string[];
  customfield_10700: Customfield[];
  customfield_11606: Customfield;
  customfield_11617: Customfield;
  customfield_11621: Customfield;
  customfield_11708: string;
  customfield_12100: Customfield;
  customfield_12103: Customfield;
  customfield_12600: string;
  customfield_13006: Customfield;
  customfield_14600?: Customfield[];
  customfield_14601: number;
  customfield_14602: string;
  description: string;
  fixVersions: FixVersion[];
  issuetype: Issuetype;
  labels: string[];
  priority: Priority;
  project: Project;
  reporter: Reporter;
  resolution: Resolution;
  resolutiondate: string;
  status: Status;
  summary: string;
  timeestimate: number;
  timeoriginalestimate: number;
  timespent: number;
  timetracking: Timetracking;
  updated: string;
  versions: Version[];
}

export interface Aggregateprogress {
  progress: number;
  total: number;
}

export interface Assignee {
  active: boolean;
  displayName: string;
  emailAddress: string;
  key: string;
  name: string;
  self: string;
  timeZone: string;
}

export interface Comment {
  comments: any;
  maxResults: number;
  startAt: number;
  total: number;
}

export interface Component {
  description: string;
  id: string;
  name: string;
  self: string;
}

export interface Child {
  id: string;
  name: string;
  value: string;
}

export interface Customfield {
  id: string;
  name: string;
  value: string;
  child: Child;
}

export interface FixVersion {
  name: string;
}

export interface Issuetype {
  avatarId: number;
  id: string;
  name: string;
  self: string;
}

export interface Priority {
  iconUrl: string;
  id: string;
  name: string;
  self: string;
}

export interface Project {
  id: string;
  key: string;
  name: string;
  self: string;
}

export interface Reporter {
  active: boolean;
  displayName: string;
  emailAddress: string;
  key: string;
  name: string;
  self: string;
  timeZone: string;
}

export interface Resolution {
  id: string;
  name: string;
}

export interface Status {
  description: string;
  id: string;
  name: string;
  self: string;
  statusCategory: StatusCategory;
}

export interface StatusCategory {
  colorName: string;
  id: number;
  key: string;
  name: string;
  self: string;
}

export interface Timetracking {
  originalEstimate: string;
  originalEstimateSeconds: number;
  remainingEstimate: string;
  remainingEstimateSeconds: number;
}

export interface Version {
  name: string;
}
export interface FetchJiraResp {
  expand: string;
  issues: Issue[];
  maxResults: number;
  startAt: number;
  total: number;
}
export const fetchJira = (data: FetchJiraReq): Promise<FetchJiraResp> =>
  request.post("/customer/jira", data);
