import request from "@/utils/request";
import { FetchJiraResp } from "../jira/service";

interface WeComWorkHourReq {
  count: number;
  component: string;
  description: string;
  time: string;
  vid: string;
}

export const weComWorkHour = (data: WeComWorkHourReq): Promise<string> =>
  request.post("/work-hour", data);

export const fetchWeComWorkHourJira = (cid: string): Promise<FetchJiraResp> =>
  request.get("/work-hour", {
    params: { cid },
  });
