import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "welcome" }
const _hoisted_4 = { class: "global_loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_cid = _resolveComponent("input-cid")
  const _component_customer_company = _resolveComponent("customer-company")
  const _component_van_tab = _resolveComponent("van-tab")
  const _component_customer_vendor = _resolveComponent("customer-vendor")
  const _component_jira = _resolveComponent("jira")
  const _component_work = _resolveComponent("work")
  const _component_van_tabs = _resolveComponent("van-tabs")
  const _component_van_loading = _resolveComponent("van-loading")
  const _component_van_popup = _resolveComponent("van-popup")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      (!_ctx.state.showContent)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode("h3", _hoisted_3, "欢迎," + _toDisplayString(_ctx.state.userInfo?.name), 1),
            _createVNode(_component_input_cid)
          ]))
        : (_openBlock(), _createBlock(_component_van_tabs, {
            key: 1,
            class: "nav",
            "line-height": "1px",
            sticky: "",
            "title-active-color": "#2c90f3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_tab, { title: "客户档案" }, {
                default: _withCtx(() => [
                  _createVNode(_component_customer_company)
                ]),
                _: 1
              }),
              _createVNode(_component_van_tab, { title: "项目档案" }, {
                default: _withCtx(() => [
                  _createVNode(_component_customer_vendor)
                ]),
                _: 1
              }),
              _createVNode(_component_van_tab, { title: "相关Jira" }, {
                default: _withCtx(() => [
                  _createVNode(_component_jira)
                ]),
                _: 1
              }),
              _createVNode(_component_van_tab, { title: "工时记录" }, {
                default: _withCtx(() => [
                  _createVNode(_component_work)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _createVNode(_component_van_popup, {
      class: "global_popup",
      show: _ctx.state.loading
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_van_loading, {
            type: "spinner",
            size: "50"
          })
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}