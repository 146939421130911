
import { useStore } from "@/store";
import { openUrl } from "@/utils";
import dayjs from "dayjs";
import Notify from "vant/lib/notify";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { fetchWeComWorkHourJira, weComWorkHour } from "./service";

const componentOpts = [
  "SDK",
  "音频",
  "视频",
  "WEB",
  "AD",
  "云平台",
  "集成",
  "沟通",
  "其他",
];
export default defineComponent({
  name: "work",
  setup() {
    const store = useStore();
    const state = reactive({
      refreshing: false,
      submiting: false,
      component: componentOpts[0],
      count: "1",
      vid: store.state.VID,
      time: "0",
      description: "",
      showComponentPicher: false,
      showVidPicher: false,
      vidOptions: computed(() => store.state.vidOptions),
      resolvedCount: 0,
      resolvedTime: 0,
      resolvedComponents: new Set(),
    });

    const fetchWorkHourJira = () => {
      state.refreshing = true;
      fetchWeComWorkHourJira(store.state.CID)
        .then((data) => {
          let startDay = dayjs();
          startDay =
            startDay.day() > 3 || startDay.day() === 0
              ? startDay
              : startDay.subtract(7, "day");
          startDay = startDay.day(4).startOf("day");

          let count = 0,
            time = 0;
          let components = new Set();
          data.issues &&
            data.issues.forEach((v) => {
              if (dayjs(v.fields.created) > startDay) {
                count = count + v.fields.customfield_14601;
                time = time + Number(v.fields.customfield_14602);
                v.fields.components.forEach((c) => components.add(c.name));
              }
            });
          state.resolvedCount = count;
          state.resolvedTime = time;
          state.resolvedComponents = components;
        })
        .finally(() => {
          state.refreshing = false;
        });
    };

    const onSubmit = (values: any) => {
      const { count, ...param } = values;
      state.submiting = true;
      weComWorkHour({
        count: Number(count),
        ...param,
      })
        .then(async () => {
          Notify({ type: "success", message: "提交成功" });
          state.count = "1";
          state.time = "0";
          state.description = "";
          // 更新工时
          fetchWorkHourJira();
        })
        .catch(() => {
          Notify({ type: "danger", message: "提交失败请稍后重试" });
        })
        .finally(() => {
          state.submiting = false;
        });
    };

    const onComponentConfirm = (value: string) => {
      state.component = value;
      state.showComponentPicher = false;
    };

    const onVidConfirm = (value: string) => {
      state.vid = value;
      state.showVidPicher = false;
    };

    const openFilter = () => {
      openUrl(
        `https://jira.agoralab.co/issues/?jql=project = TSD AND CompanyID ~ ${store.state.CID} ORDER BY created DESC`
      );
    };

    onMounted(() => {
      fetchWorkHourJira();
    });

    return {
      state,
      componentOpts,
      onSubmit,
      onComponentConfirm,
      onVidConfirm,
      fetchWorkHourJira,
      openFilter,
    };
  },
});
